<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="gettimesheet"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 id="timesheetTitle" class="mb-0 font-weight-normal">
              {{ $t('report.txtTimesheet') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="timesheetExportButton" v-on:click="exportTimesheetReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
            </CCol>
            <CCol lg="10" md="10" sm="9" col="9">
              <div>
                <CInput
                  id="timesheetKeywordGettimesheet"
                  v-model="keyword"
                  :placeholder="$t('searchEmployees')"
                  @input="gettimesheet()"
                />
              </div>
            </CCol>
            <CCol lg="2" md="2" sm="3" col="3">
              <CButton id="timesheetSearchButton" v-on:click="gettimesheet()" block color="success">
                {{ $t('search') }}
              </CButton>
            </CCol>
            <CCol sm="12" lg="12">
              <hr />
              <CDataTable id="timesheetDatatable" :items="items()" :fields="fields" hover border style="white-space: nowrap">
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="gettimesheet">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import report from '@/services/report'
import i18n from '@/plugins/i18n'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    Pagination, 
    HelpButton
  },
  data() {
    return {
      data: [],
      isSearch: '',
      keyword: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'), _classes: 'text-left text-dark font-weight-normal' },
        {
          key: 'name',
          label: this.$i18n.t('employeesname'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        { key: 'timeIn', label: this.$i18n.t('timeIn'), _classes: 'text-left font-weight-normal text-dark', },
        {
          key: 'timeOut',
          label: this.$i18n.t('timeOut'),
          _classes: 'text-left font-weight-normal text-dark',
        },
        { key: 'time', label: this.$i18n.t('time'), _classes: 'text-left font-weight-normal text-dark',},
      ]
    },
    
  },
  created() {
    this.gettimesheet()
  },
  methods: {
    ...util,
    items() {
      let data = this.data
      let detail = []
      let startTime = ''
      let endTime = ''
      let hours = ''
      let hourstime = ''
      let date = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].timeIn == undefined || data[i].timeIn == '') {
          startTime = '-'
          date = '-'
        } else {
          startTime = moment(String(data[i].timeIn)).format(
            'DD/MM/YYYY HH:mm:ss'
          )
          date = moment(String(data[i].timeIn)).format('DD/MM/YYYY')
        }
        if (data[i].timeOut == undefined || data[i].timeOut == '') {
          endTime = '-'
        } else {
          endTime = moment(String(data[i].timeOut)).format(
            'DD/MM/YYYY HH:mm:ss'
          )
        }
        if (startTime != '-' && endTime != '-') {
          hours = moment
            .duration(
              moment(endTime, 'YYYY/MM/DD HH:mm').diff(
                moment(startTime, 'YYYY/MM/DD HH:mm')
              )
            )
            .asSeconds()
        } else {
          hours = '-'
        }
        if (endTime != '-' && startTime != '-') {
          if (i18n.locale == 'th') {
            hourstime = moment
              .utc(moment.duration(hours, 'seconds').asMilliseconds())
              .format('HH ชั่วโมง mm นาที')
          } else {
            hourstime = moment
              .utc(moment.duration(hours, 'seconds').asMilliseconds())
              .format('HH :mm ')
          }
        } else {
          hourstime = '-'
        }
        detail.push({
          date: date,
          name: data[i].employee.username,
          timeIn: startTime,
          timeOut: endTime,
          time: hourstime,
        })
      }
      return detail
    },
    exportTimesheetReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        start: startAt,
        end: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/shop/v1.0/' + uid + '/timesheet/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานชั่วโมงการทำงาน.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    gettimesheet(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        start: startAt,
        end: endAt,
        page: page,
        limit: 20,
        keyword: this.keyword,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/shop/v1.0/' + uid + '/timesheet/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.meta_data.prev_page_url = res.data.paginate.currentPage - 1
          //this.loading = false;
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
